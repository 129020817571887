<template>
  <div class="box">
    <div class="content">
      <div class="title">用户反馈</div>
      <textarea
        class="textarea"
        placeholder="您的意见是我们最大的动力"
        v-model="val"
      ></textarea>
    </div>
    <van-button type="info" class="sub" :loading="formIng" @click="sub"
      >立即提交</van-button
    >
    <!-- <div class="sub" @click="sub">立即提交</div> -->
  </div>
</template>

<script>
import Vue from "vue";
import { Toast } from "vant";

Vue.use(Toast);
import url from "@/service/url-config";
export default {
  data() {
    return {
      val: "",
      formIng: false,
    };
  },
  created() {},
  methods: {
    sub() {
      this.formIng = true;
      this.$http.post(url.feedback, { content: this.val }).then((res) => {
        this.formIng = false;
        if (res.code == 200) {
          Toast({
            type: "success",
            message: "提交成功",
            onClose: () => {
              this.$router.push({
                path: "my",
              });
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f9f9f9;
  min-height: calc(100vh - 66px);
  padding-top: 66px;
  .content {
    width: 325px;
    height: 248px;
    margin: 0 auto;
    background: #ffffff;
    padding: 15px;
    .title {
      font-size: 14px;
      color: #666666;
    }
    .textarea {
      width: 303px;
      height: 196px;
      border-radius: 2px;
      border: 1px solid #ededed;
      font-size: 12px;
      margin-top: 10px;
      padding: 10px;
    }
  }
  .sub {
    width: 220px;
    height: 42px;
    background: #0084ff;
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    line-height: 42px;
    position: fixed;
    left: 50%;
    margin-left: -110px;
    bottom: 100px;
  }
}
</style>
